import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
Vue.config.devtools = true;
// import './permission'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './directives';
import { message } from '@/utils/resetMessage';
import MMCSTL from 'mmc-stl-vue2'; // mmc组件
Vue.use(MMCSTL);

import './styles/index.scss';
import './styles/animate.css';

Vue.use(ElementUI);
Vue.prototype.$message = message;
Vue.config.productionTip = false;
// 注册事件总线
Vue.prototype.$bus = new Vue({});

import animated from 'animate.css';

Vue.use(animated);

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app');
