import axios from 'axios';
import Vue from 'vue';
import { resetMessage } from './message';
import router from '@/router';
import store from '@/store';

const $axios = axios.create({
  baseURL: process.env.VUE_APP_BASE_API_TG
});

Vue.prototype.$http = axios;

const loading = null;

/**
 * 请求拦截器
 * 用于处理请求前添加loading、判断是否已保存token，并在每次请求头部添加token
 */
$axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('tmj_token');
    if (token) {
      config.headers['token'] = token;
      config.headers['channel'] = 'channel'; // 渠道 主平台 子平台 后台
    }
    return config;
  },
  (error) => {
    console.log('进来了2....');
    return Promise.reject(error);
  }
);
/**
 * 响应拦截器
 * 用于处理loading状态关闭、请求成功回调、响应错误处理
 */
$axios.interceptors.response.use(
  (response) => {
    console.log('请求的URL:', response.config.url);
    if (loading) {
      loading.close();
    }
    console.log('回应参数...', response);
    const status = response.status;
    const res = response.data;
    // 请求成功返回response.data
    if ((status >= 200 && status < 300) || status === 304) {
      switch (res.code) {
        case 200:
          return Promise.resolve(response.data);

        case 525:
          if (store.state.isIframe) {
            resetMessage.error(response.data.msg);
          }
          return Promise.resolve(response.data);

        case 401:
          // resetMessage.error('登录失效');
          store.commit('user/LOGIN_OUT');
          router.replace({
            path: '/login'
          });
          return Promise.reject(response);

        case 403:
          if (store.state.isIframe) {
            resetMessage.error(response.data.msg);
          }
          break;

        default:
          if (response.config.responseType === 'blob') {
            return Promise.resolve(response.data);
          }
          resetMessage.error(response?.data?.msg || '网络请求错误');
          return Promise.reject(response.data);
      }
    } else {
      return Promise.reject(response);
    }
  },
  (error) => {
    if (loading) {
      loading.close();
    }
    console.log(error);
    if (error.response) {
      switch (error.response.data.code) {
        case 401:
          // 返回401 清除token信息并跳转到登陆页面
          // store.commit("user/LOGIN_OUT");
          // router.replace({
          //   path: "/login",
          //   query: {
          //     redirect: router.currentRoute.fullPath,
          //   },
          // });
          break;
        case 403:
          // 返回403 清除token信息并跳转到登陆页面
          // store.commit("user/LOGIN_OUT");
          resetMessage.error('token过期');
          /* router.replace({
            path: "/login",
            query: {
              redirect: router.currentRoute.fullPath,
            },
          }); */
          break;
        case 404:
          resetMessage.error('网络请求不存在');
          break;
        default:
          // 统一错误提示弹窗
          resetMessage.error(error.response?.data?.msg || '网络请求错误');
      }
    } else {
      // 请求超时或者网络有问题
      if (error.message.includes('timeout')) {
        resetMessage.error('请求超时！请检查网络是否正常');
      } else {
        resetMessage.error('请求失败，请检查网络是否已连接');
      }
    }
    return Promise.reject(error);
  }
);

export default $axios;
