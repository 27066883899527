import Vue from 'vue';
import Vuex from 'vuex';
import {
  User,
  Airspace
} from '@/api';
import uavApplications from './uavApplications';
import uavApplicationsEagleNest from './uavApplicationsEagleNest';
import subplatform from './subplatform';

const entitiesAll = {};
const positionsAll = {};
let polygonEntity = [];

Vue.use(Vuex);

const str = localStorage.getItem('permission');
let permission = [];
try {
  permission = JSON.parse(str);
} catch (e) {
  console.log('permission error', e);
}

let userInfo = null;
try {
  userInfo = JSON.parse(localStorage.getItem('user_info'));
} catch (e) { }

export default new Vuex.Store({
  state: {
    permission, // 用户权限
    active: -1,
    userInfo: userInfo,
    uavOnlineState: false,
    uavLoginInfo: '',
    AILIST: false,
    logs: [],
    ailist_active: true,
    ai_url_obj: {},
    // 设备名称
    deviceName2: '', // 设备名称
    deviceName1: '',
    routeName1: '',
    routeName2: '',
    taskName1: '',
    taskName2: '',
    // 是否来自删除航线
    isFromAirline: false,
    // 窗口状态
    sidebarStatus: null,
    isCesium: false, // 是否显示Cesium，否则为显示charts
    isMap3d: true, // 是否显示3D地图，否则显示2D
    mapMode: 0, // 地图模式 0: 卫星图 1: 夜景图
    openLeftNavSilder: false, // 是否打开左侧导航栏
    openRightNavSilder: false, // 是否打开右侧导航栏
    projectData: null, // 进入项目后保存的项目数据
    editProjectData: null, // 编辑项目的数据
    taskDeviceData: null, // 打开任务详情时绑定的无人机数据
    showDemo: false, // 播放演示视频
    leftMenuAction: 1, // 左侧菜单需要显示的内容
    isIframe: sessionStorage.getItem('isIframe') === '1' // 是否iframe中显示
  },
  mutations: {
    CHANGE_ACTYIVE(state, con) {
      // console.log("change_active", state, con);
      state.active = con;
    },
    SET_USERINFO(state, con) {
      state.userInfo = con;
    },
    SET_UAVSTATE(state, con) {
      state.uavOnlineState = con;
    },
    SET_UAVLOGIN_INFO(state, con) {
      state.uavLoginInfo = con;
    },
    active_ailist(state, con) {
      this.AILIST = con;
    },
    SET_LOGS(state, con) {
      state.logs.push({
        log: '您进行了' + con.log + '操作',
        time: con.time
      });
      // state.logs.log = "您进行了" + con.log + "操作"
      // state.logs.time = con.time
    },
    SET_ISFORMAIRLINE_STATUS(state, con) {
      state.isFromAirline = con;
    },
    SET_AIURLOBJ(state, con) {
      state.ai_url_obj = con;
    },
    // 窗口状态赋值
    SET_SIDEBAR_STATUS(state, con) {
      state.sidebarStatus = con;
    },
    // 地图状态赋值
    SET_CESIUM_STATE(state, con) {
      state.isCesium = con;
    },
    SET_OPEN_LEFT_NAV_SILDER(state, con) {
      state.openLeftNavSilder = con;
    },
    SET_OPEN_RIGHT_NAV_SILDER(state, con) {
      state.openRightNavSilder = con;
    },
    SET_PROJECT_DATA(state, con) {
      state.projectData = con;
    },
    SET_PERMISSION(state, con) {
      state.permission = con;
      localStorage.setItem('permission', JSON.stringify(state.permission));
    },
    SET_EDIT_PROJECT_DATA(state, con) {
      state.editProjectData = con;
    },
    SET_TASK_DEVICE_DATA(state, con) {
      state.taskDeviceData = con;
    },
    SET_SHOW_DEMO(state, con) {
      state.showDemo = con;
    },
    SET_IS_MAP3D(state, con) {
      state.isMap3d = con;
    },
    SET_MAP_MODE(state, con) {
      state.mapMode = con;
    },
    SET_LEFT_MENU_ACTION(state, con) {
      state.leftMenuAction = con;
    },
    SET_IS_IFRAME(state, con) {
      state.isIframe = con;
    }
  },
  actions: {
    async getUserInfo({
      commit
    }) {
      try {
        const response = await User.CheckToken({
          token: localStorage.getItem('tmj_token')
        });
        const {
          data,
          code
        } = response;
        if (code === 200) {
          commit('SET_USERINFO', data);
          return Promise.resolve(response.data);
        } else {
          window.localStorage.removeItem('tmj_token');
          return Promise.reject(response.message);
        }
      } catch (error) {
        console.error(error);
        return Promise.reject(error.message);
      }
    },
    // remove token
    resetToken({
      commit
    }) {
      return new Promise((resolve) => {
        window.localStorage.removeItem('tmj_token');
        // commit('RESET_STATE')
        resolve();
      });
    },
    async showAllArea(ctx, viewer) {
      console.log(viewer, '158');
      const param = {
        page: 1,
        pageSize: 999
      };
      await Airspace.airspaceList({
        ...param
      }).then((resp) => {
        console.log(resp, '164');
        const clearAllMarker = () => {
          if (Object.values(entitiesAll).length) {
            for (const key in entitiesAll) {
              entitiesAll[key].map((element) => {
                viewer.entities.remove(element);
              });
            }
            for (const key in positionsAll) {
              positionsAll[key].map((element) => {
                viewer.entities.remove(element);
              });
            }
          }
        };
        clearAllMarker();
        resp.data.records.map((data) => {
          console.log(!data.isEnable);
          if (!data.isEnable) return;
          entitiesAll[data.id] = [];
          positionsAll[data.id] = [];
          const color = data.color;
          const text = String(data.name) || '';
          const positions = data.region.split(' ').join(',').split(',');
          positions.forEach((element, index) => (positions[index] = Number(element)));
          const coordinates = Cesium.Cartesian3.fromDegreesArray(positions);
          const creatPoint = (position, index, data) => {
            // 添加点位
            const pointEntity = viewer.entities.add({
              position: position,
              point: {
                pixelSize: 20,
                color: Cesium.Color.RED,
                outlineColor: Cesium.Color.WHITE,
                outlineWidth: 2
              },
              label: {
                text: `${index + 1}`,
                scale: 0.5,
                font: 'bold 24px Microsoft YaHei',
                fillColor: Cesium.Color.WHITE,
                horizontalOrigin: Cesium.VerticalOrigin.CENTER,
                verticalOrigin: Cesium.VerticalOrigin.CENTER,
                disableDepthTestDistance: Number.POSITIVE_INFINITY,
                showBackground: false,
                outlineWidth: 0
              }
            });

            entitiesAll[data.id].push(pointEntity);
            positionsAll[data.id].push(position);
          };
          const createPolyline = (position, data) => {
            const positionList = positionsAll[data.id];
            // 添加连线
            if (positionList.length > 1) {
              var lineEntity = viewer.entities.add({
                polyline: {
                  positions: [positionList[positionList.length - 2], position],
                  width: 2,
                  material: Cesium.Color.RED
                }
              });
              entitiesAll[data.id].push(lineEntity);
              // 计算两个点之间的距离
              const distance = Cesium.Cartesian3.distance(
                position,
                positionList[positionList.length - 2]
              );
              console.log(positionList[positionList.length - 2], '');
              // 计算线的中心位置
              const center = Cesium.Cartesian3.add(
                positionList[positionList.length - 2],
                position,
                new Cesium.Cartesian3()
              );
              const newcenter = Cesium.Cartesian3.divideByScalar(center, 2, center);
              // 定义一个函数来更新 label 的字体大小
              function updateLabelFontSize() {
                var cameraHeight = viewer.camera.positionCartographic.height;
                var fontSizeScale = Math.min(1, 10000 / cameraHeight); // 根据相机高度调整字体大小,但不超过 1
                labelEntity.label.font = (18 * fontSizeScale) + "px sans-serif";
              }
              // 添加 label 时保存对应的 entity
              var labelEntity = viewer.entities.add({
                position: newcenter,
                label: {
                  text: distance.toFixed(2) + 'm',
                  font: '18px sans-serif',
                  fillColor: Cesium.Color.WHITE,
                  outlineColor: Cesium.Color.BLACK,
                  outlineWidth: 2,
                  style: Cesium.LabelStyle.FILL_AND_OUTLINE,
                  pixelOffset: new Cesium.Cartesian2(0, -20),
                  disableDepthTestDistance: Number.POSITIVE_INFINITY,
                }
              });

              // 添加事件监听,在相机移动时更新 label 的字体大小
              viewer.camera.changed.addEventListener(updateLabelFontSize);
              entitiesAll[data.id].push(labelEntity);
            }
          };
          const createAfterPolyline = (positions, data) => {
            if (positions.length > 1) {
              const lineEntity = viewer.entities.add({
                polyline: {
                  positions: [positions[0], positions[positions.length - 1]],
                  width: 2,
                  material: Cesium.Color.RED
                }
              });
              entitiesAll[data.id].push(lineEntity);
              // 计算两个点之间的距离
              const distance = Cesium.Cartesian3.distance(
                positions[0],
                positions[positions.length - 1]
              );
              console.log('创建最后一条边-距离', distance);

              // 计算线的中心位置
              const center = Cesium.Cartesian3.add(
                positions[positions.length - 1],
                positions[0],
                new Cesium.Cartesian3()
              );
              console.log('创建最后一条边-距离计算线的中心位置', center);

              const newcenter = Cesium.Cartesian3.divideByScalar(center, 2, center);
              // 定义一个函数来更新 label 的字体大小
              function updateLabelFontSize() {
                var cameraHeight = viewer.camera.positionCartographic.height;
                var fontSizeScale = Math.min(1, 10000 / cameraHeight); // 根据相机高度调整字体大小，但不超过 1
                labelEntity.label.font = (18 * fontSizeScale) + "px sans-serif";
              }

              // 创建 Label 标签来显示距离
              var labelEntity = viewer.entities.add({
                position: newcenter,
                label: {
                  text: distance.toFixed(2) + 'm',
                  font: '18px sans-serif',
                  fillColor: Cesium.Color.WHITE,
                  outlineColor: Cesium.Color.BLACK,
                  outlineWidth: 2,
                  style: Cesium.LabelStyle.FILL_AND_OUTLINE,
                  pixelOffset: new Cesium.Cartesian2(0, -20),
                  disableDepthTestDistance: Number.POSITIVE_INFINITY,
                }
              });
              // 添加事件监听,在相机移动时更新 label 的字体大小
              viewer.camera.changed.addEventListener(updateLabelFontSize);
              entitiesAll[data.id].push(labelEntity);
            }
          };
          const createCircle = (position, data) => {
            let color = '#ff0000';
            if (data.color) {
              color = data.color;
            }
            const radius = Cesium.Cartesian3.distance(position[0], position[1]);
            const center = position[0];
            try {
              polygonEntity = viewer.entities.add({
                position: center,
                label: {
                  text: text,
                  scale: 1.2, // 文本缩放比例
                  verticalOrigin: Cesium.VerticalOrigin.CENTER, // 垂直方向居中对齐
                  pixelOffset: new Cesium.Cartesian2(20, 20), // 文本偏移量
                  disableDepthTestDistance: Number.POSITIVE_INFINITY,
                },
                ellipse: {
                  semiMinorAxis: radius,
                  semiMajorAxis: radius,
                  material: Cesium.Color.fromCssColorString(color),
                  outline: true,
                  outlineColor: Cesium.Color.WHITE
                }
              });
              // viewer.flyTo(polygonEntity)
              entitiesAll[data.id].push(polygonEntity);
            } catch (error) {
              console.log(error);
            }
          };
          // 代表矩形区域
          if (coordinates.length > 2) {
            for (let i = 0; i < coordinates.length; i++) {
              const position = coordinates[i];
              creatPoint(position, i, data);
              createPolyline(position, data);
            }
            polygonEntity = viewer.entities.add({
              polygon: {
                hierarchy: coordinates,
                material: Cesium.Color.fromCssColorString(color)
              }
            });
            const centerPosition = Cesium.BoundingSphere.fromPoints(coordinates).center;
            const labelEntity = viewer.entities.add({
              position: centerPosition,
              label: {
                text: text,
                scale: 0.9, // 文本缩放比例
                verticalOrigin: Cesium.VerticalOrigin.CENTER, // 垂直方向居中对齐
                pixelOffset: new Cesium.Cartesian2(), // 文本偏移量
                disableDepthTestDistance: Number.POSITIVE_INFINITY,
              }
            });
            // 定义一个函数来更新 label 的字体大小
            function updateLabelFontSize() {
              var cameraHeight = viewer.camera.positionCartographic.height;
              var fontSizeScale = Math.min(1, 10000 / cameraHeight); // 根据相机高度调整字体大小，但不超过 1
              labelEntity.label.font = (18 * fontSizeScale) + "px sans-serif";
            }
            viewer.camera.changed.addEventListener(updateLabelFontSize);

            createAfterPolyline(coordinates, data);
            entitiesAll[data.id].push(polygonEntity);
            entitiesAll[data.id].push(labelEntity);
          } else if (coordinates.length == 2) {
            // 代表圆形区域
            for (let i = 0; i < coordinates.length; i++) {
              const position = coordinates[i];
              creatPoint(position, i, data);
              createPolyline(position, data);
            }
            createCircle(coordinates, data);
          }
        });
      });
    },
    flyTo(ctx, {
      viewer,
      data
    }) {
      viewer.flyTo(entitiesAll[data.id]);
    }
  },
  modules: {
    uavApplications,
    uavApplicationsEagleNest,
    subplatform
  }
});