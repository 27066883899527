<template>
  <div v-pms="['工具栏']" class="cesium-toolbar" :class="{ disabled: disabled }">
    <div v-show="isShow" v-pms="['工具栏', '飞入原点']" class="toolbar_box_hide">
      <div v-if="isLogin" class="toolbar_box" @click="() => $emit('toolbar-click', 1)">
        <el-tooltip content="飞入原点" placement="top" :hide-after="1000" effect="dark">
          <img src="~@/assets/basic-tools/icon_set_origin.svg" />
        </el-tooltip>
      </div>
      <div
        v-if="isLogin"
        v-pms="['工具栏', '设置原点']"
        class="toolbar_box"
        @click="() => $emit('toolbar-click', 2)"
      >
        <el-tooltip content="设置原点" placement="top" effect="dark" :hide-after="1000">
          <img src="~@/assets/basic-tools/icon_get_origin.svg" />
        </el-tooltip>
      </div>
      <el-popover placement="bottom" width="120" trigger="hover" popper-class="el-popover-box">
        <div class="hover_box">
          <div class="toolbar_box" @click="() => $emit('toolbar-click', 3)">
            <img src="~@/assets/basic-tools/icon_horizontal.svg" />
            <span>水平测量</span>
          </div>
          <div class="toolbar_box" @click="() => $emit('toolbar-click', 4)">
            <i class="iconfont icon-tchuizhiceliang" />
            <span>垂直测量</span>
          </div>
          <div class="toolbar_box" @click="() => $emit('toolbar-click', 5)">
            <img src="~@/assets/basic-tools/icon_area.svg" />
            <span>面积测量</span>
          </div>
          <div class="toolbar_box" @click="() => $emit('toolbar-click', 6)">
            <img src="~@/assets/basic-tools/icon_volume.svg" />
            <span>体积测量</span>
          </div>
        </div>
        <div slot="reference" v-pms="['工具栏', '测量工具']" class="toolbar_box">
          <el-tooltip content="测量工具" placement="top" effect="dark" :hide-after="1000">
            <img src="~@/assets/basic-tools/icon_measure.svg" />
          </el-tooltip>
        </div>
      </el-popover>
      <el-popover placement="bottom" width="120" trigger="hover" popper-class="el-popover-box">
        <div class="hover_box">
          <div class="toolbar_box" @click="() => $emit('toolbar-click', 7)">
            <img src="~@/assets/basic-tools/icon_topsight.svg" />
            <span>顶视</span>
          </div>
          <div class="toolbar_box" @click="() => $emit('toolbar-click', 8)">
            <img src="~@/assets/basic-tools/icon_overlook.svg" />
            <span>俯视</span>
          </div>
        </div>
        <div slot="reference" v-pms="['工具栏', '视图']" class="toolbar_box">
          <el-tooltip content="视图" placement="top" effect="dark" :hide-after="1000">
            <img src="~@/assets/basic-tools/icon_view.svg" />
          </el-tooltip>
        </div>
      </el-popover>
      <div v-pms="['工具栏', '指北']" class="toolbar_box" @click="() => $emit('toolbar-click', 9)">
        <el-tooltip content="指北" placement="top" effect="dark" :hide-after="1000">
          <img src="~@/assets/basic-tools/icon_north.svg" />
        </el-tooltip>
      </div>
      <div
        v-pms="['工具栏', '人视角']"
        class="toolbar_box"
        @click="() => $emit('toolbar-click', 10)"
      >
        <el-tooltip content="人视角" placement="top" effect="dark" :hide-after="1000">
          <img src="~@/assets/basic-tools/icon_perspective.svg" />
        </el-tooltip>
      </div>
      <div v-pms="['工具栏', '全屏']" class="toolbar_box" @click="handleFullscreen">
        <el-tooltip
          v-if="!fullscreen"
          content="全屏"
          placement="top"
          effect="dark"
          :hide-after="1000"
        >
          <img src="~@/assets/basic-tools/icon_fullscreen.svg" />
        </el-tooltip>
        <el-tooltip v-else content="退出全屏" placement="top" effect="dark">
          <img src="~@/assets/basic-tools/icon_smallscreen.svg" />
        </el-tooltip>
      </div>
    </div>
    <div class="toolbar_box" @click="showToolbar">
      <el-tooltip content="工具栏" placement="top" effect="dark">
        <!-- <img src="~@/assets/basic-tools/icon_toolkit.svg" /> -->
        <img :src="imgToolbox" width="24px" height="24px" />
      </el-tooltip>
    </div>
  </div>
</template>

<script>
import { DOM } from '@/utils/dom.js';
import imgToolbox from '@/assets/basic-tools/toolbox.png';
import imgToolboxOpen from '@/assets/basic-tools/toolbox_open.png';
import imgToolboxClose from '@/assets/basic-tools/toolbox_close.png';

export default {
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      fullscreen: false,
      isShow: false,
      collapse: false,
      command: false,
      notFlyIsShow: true,
      isLogin: true,
      firstImgToolbox: true // 第一次进入页面时,不用动画png
    };
  },
  computed: {
    imgToolbox() {
      if (this.firstImgToolbox) {
        return imgToolbox;
      } else {
        if (this.isShow) {
          return imgToolboxOpen;
        } else {
          return imgToolboxClose;
        }
      }
    }
  },
  watch: {
    '$store.state.device.flag'(newData) {
      this.collapse = newData;
    }
  },
  created() {},
  mounted() {
    this.isLogin = !!window.localStorage.getItem('tmj_token');
    // 控制重要元素下拉菜单隐藏
    document.addEventListener('click', (e) => {
      const iconEl = document.querySelector('.cesium-toolbar'); // 这里是要隐藏的弹窗 类名
      if (iconEl && !iconEl.contains(e.target)) {
        // contains 方法 就是查看包含关系
        // contains 方法 本身含义：用于判断字符串中是否包含指定的字符或字符串
        // 返回值 :如果包含指定的字符或字符串返回 true，否则返回 false。
        this.isShow = false;
      }
    });
  },
  methods: {
    handleClick() {
      this.notFlyIsShow = !this.notFlyIsShow;
      this.$emit('toolbar-click', 11, this.notFlyIsShow);
    },
    handleFullscreen() {
      this.fullscreen = !this.fullscreen;
      if (this.fullscreen) {
        DOM.fullscreen();
      } else {
        DOM.exit_fullscreen();
      }
    },
    moveToolbar(e) {
      if (e == 'center') {
        this.collapse = false;
      } else if (e == 'left') {
        this.collapse = true;
      } else {
        this.collapse = !this.collapse;
      }
    },
    showToolbar() {
      if (this.disabled) {
        return;
      }
      this.isShow = !this.isShow;
      this.firstImgToolbox = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.cesium-toolbar {
  position: relative;
  display: flex;
  // transition: width 0.75s, left 0.75s;
  -moz-transition: width 1s; /* Firefox 4 */
  transition: width 1s; /* Safari and Chrome */
  -o-transition: width 1s; /* Opera */
  border: 1px solid #3bc1e5;
  border-radius: 4px;
  background: rgba(19, 21, 24, 0.7);
  box-shadow: inset 0 1px 8px 0 #2883b3, inset 0 4px 48px 0 rgba(53, 76, 147, 0.5),
    inset 0 16px 48px 0 rgba(93, 173, 255, 0.15);

  &.disabled {
    opacity: 0.5;
  }

  .toolbar_box {
    // background: rgba(19, 21, 24, 0.7);
    // box-shadow: inset 0 1px 8px 0 #2883b3,
    //   inset 0 4px 48px 0 rgba(53, 76, 147, 0.5),
    //   inset 0 16px 48px 0 rgba(93, 173, 255, 0.15);
    width: 48px;
    height: 48px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    // border-radius: 4px 0 0 4px;
  }
  .toolbar_box_hide {
    display: flex;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }
}
.iconfont {
  font-size: 26px !important;
  color: rgb(67, 222, 255);
}
</style>

<style lang="scss">
.el-popover-box {
  padding: 0 !important;
  background: rgba(0, 0, 0, 0.7) !important;
  // background: rgba(19, 21, 24, 0.7);
  box-shadow: inset 0 1px 8px 0 #2883b3, inset 0 4px 48px 0 rgba(53, 76, 147, 0.5),
    inset 0 16px 48px 0 rgba(93, 173, 255, 0.15) !important;
  border: 1px solid #3bc1e5 !important;
  min-width: auto !important;
  .toolbar_box {
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 48px;
    color: #cacaca;
    cursor: pointer;
    text-align: center;
    &:hover {
      opacity: 0.7;
      // background: red;
    }
    & > span {
      margin-left: 10px;
    }
    & > img {
      width: 20px;
      height: 20px;
      // margin-top: 15px;
    }
  }
}
</style>
